.add-db-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-db-form-container {
  background: white;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.add-db-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4em  2em;
}

.add-db-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.add-db-form-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.add-db-form .form-group {
  margin-bottom: 0.5rem;
  width: 100%;
}

.add-db-form label {
  display: block;
  margin-bottom: 0.2em;
  color: #333;
  font-weight: 500;
}

.add-db-form input,
.add-db-form select {
  width: 100%;
  padding: 0.1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.add-db-form input.error {
  border-color: #dc3545;
}

.error-message {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
}

.error-title {
  color: #cf1322;
  font-weight: 500;
  margin-bottom: 4px;
}

.error-details {
  color: #595959;
  font-size: 0.9em;
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid #ffccc7;
}

.submit-error {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;
}

.cancel-button,
.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.cancel-button {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  color: #333;
}

.submit-button {
  background-color: #007bff;
  border: none;
  color: white;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cancel-button:hover {
  background-color: #e9ecef;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
} 