.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}

.auth-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 1px 2px #286bb3;
  width: 100%;
  max-width: 400px;
}

.auth-form h2 {
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.auth-button {
  width: 100%;
  padding:  0.25rem;
  background-color: #286bb3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.auth-button:hover {
  background-color: #286bb3;
}

.auth-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.auth-links {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
}

.auth-links a {
  color: #286bb3;
  text-decoration: none;
}

.auth-links a:hover {
  text-decoration: underline;
}

.register-link {
  margin-top: 0.5rem;
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.success-message {
  color: #28a745;
  background-color: #d4edda;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-logo {
  text-align: center;
  margin-bottom: 2.5rem;
  padding: 1rem;
}

.auth-logo img {
  width: 120px;
  height: auto;
  object-fit: contain;
  transition: all 0.3s;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .auth-logo img {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1024px) {
  .auth-logo img {
    width: 180px;
    height: 180px;
  }
}

/* For smaller screens */
@media (max-width: 480px) {
  .auth-logo img {
    width: 100px;
    height: 100px;
  }
  
  .auth-logo {
    margin-bottom: 2rem;
  }
}

.auth-logo h1 {
  font-size: 1.75rem;
  color: #333;
  margin: 0;
  font-weight: 600;
} 