.app-header {
  background: rgb(254, 254, 254);
  box-shadow: 0 1px 1px 0 #bdc2c7;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 30px;
}

.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.App-logo-stable {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-name {
  color: #666;
}

.logout-button {
  padding: 0.5rem 1rem;
  background-color: #ac3948;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #b82b39;
  color: rgb(173, 31, 31)  
} 