.datasource-card {
  background: white;
  border-color: #286bb3;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1em;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
}

.datasource-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px #286bb3;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.database-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.database-icon {
  font-size: 2rem;
  flex-shrink: 0;
}

.database-details {
  min-width: 0;
}

.database-details h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.database-type {
  color: #666;
  font-size: 0.875rem;
}

.connection-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: background-color 0.3s;
  flex-shrink: 0;
  margin-left: 1rem;
}

.card-content {
  flex: 1;
  margin: 0.5rem 0;
}

.connection-details {
  font-size: 0.875rem;
}

.connection-details p {
  margin: 0.5rem 0;
  color: #666;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.connection-details strong {
  color: #333;
  min-width: 80px;
}

.card-actions {
  display: flex;
  justify-content: normal;
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  background-color: #f8f9fa;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.action-button:hover {
  background-color: #e9ecef;
  color: #333;
}

.action-button.edit:hover {
  background-color: #007bff;
  color: white;
}

.action-button.delete:hover {
  background-color: #dc3545;
  color: white;
}

.action-button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .datasource-card {
    padding: 1rem;
  }

  .database-icon {
    font-size: 1.5rem;
  }

  .database-details h3 {
    font-size: 1.125rem;
  }

  .action-button {
    width: 32px;
    height: 32px;
  }
} 