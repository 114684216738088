.layout-wrapper {
  min-height: 90vh;
  padding-top: 45px;
  padding-bottom: 50px;
  display: flex;
}

.layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  transition: margin-left 0.3s ease;
}

.layout-wrapper.collapsed .layout-content {
  margin-left: 80px;
}

.main-content {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
} 