.sidebar-wrapper {
  width: auto;
  height: max-content;
  position: relative;
  left: 0;
  top: relative;
  padding-top: 0.5rem;
  transition: all 0.3s ease;
  z-index: 900;
  background: #fff;
  border-right: 1px lightblue #286bb3;
}

.sidebar-wrapper.collapsed {
  width: 80px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0.5rem;
  border-bottom: 1px light #286bb3;
}

.collapse-btn {
  background: none;
  border: none;
  color: #286bb3;
  cursor: pointer;
  padding: 0 5px;
}

.collapse-btn:hover {
  color: #85a2c1;
}

/* Additional styling for active menu items */
.ps-menu-item.active {
  background-color: #286bb3;
}