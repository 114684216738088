.dashboard-container,
.datasources-container,
.analytics-container,
.admin-container {
  padding: 2rem;
}

.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.datasources-container {
  padding: 0.5rem;
  max-width: 1500px;
  margin: 0.5em;
  width: 100%;
}

.datasources-header {
  background: white;
  padding: 0.1rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.2rem;
  margin-bottom: 0;
}

.header-content h1 {
  margin: 0;
  font-size: 1.75rem;
  color: #333;
}

.add-source-btn {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.75rem 1.25rem;
  background-color: #286bb3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.add-source-btn:hover {
  background-color: #286bb3;
}

.btn-icon {
  font-size: 1.25rem;
  line-height: 1;
}

.datasources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding: 2px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.error-container {
  text-align: center;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-data-message {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  grid-column: 1 / -1;
  color: #666;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .datasources-container {
    padding: 1rem;
  }

  .datasources-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .header-content {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }

  .add-source-btn {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .datasources-grid {
    grid-template-columns: 1fr;
  }
}

/* Adjusting the overall padding and margins for the page */
body {
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
}

/* Adjust card margins */
.datasource-card {
  margin-bottom: 0.5rem; /* Reduced margin between cards */
}

/* Adjust form margins */
.add-db-form .form-group {
  margin-bottom: 0.5rem; /* Reduced space between form fields */
} 