.chat-frame {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 40px;
  z-index: 1000;
}

.chat-frame.open {
  height: 25vh;
}

.chat-frame.expanded {
  height: 75vh;
}

.chat-frame-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #1c6bc3;
  color: white;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
}

.chat-frame-header .header-title {
  font-weight: bold;
  font-size: medium;
}

.chat-frame-header .header-actions button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.chat-frame-header .header-actions button:hover {
  opacity: 0.8;
}

.header-actions button {
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.header-actions button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.header-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.header-icon {
  font-size: 1rem;
  color: #61dafb;
}

.header-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.action-button:hover {
  color: white;
}

.chat-frame-content {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message {
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 12px;
  position: relative;
  font-size: 0.9rem;
}

.message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 4px;
}

.message.support {
  background-color: white;
  color: #1c1e21;
  align-self: flex-start;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message-timestamp {
  font-size: 0.7rem;
  opacity: 0.7;
  margin-top: 4px;
  text-align: right;
}

.chat-input {
  padding: 12px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 8px;
  background: white;
}

.chat-input input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 16px;
  outline: none;
  font-size: 0.9rem;
}

.chat-input input:focus {
  border-color: #007bff;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.chat-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.chat-input button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chat-frame.open {
    height: 40vh;
  }

  .chat-frame.expanded {
    height: 100vh;
  }
}

.message.loading {
  background: none !important;
  box-shadow: none !important;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 8px 12px;
  background: white;
  border-radius: 12px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: fit-content;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #007bff;
  border-radius: 50%;
  animation: typing 1.4s infinite;
  opacity: 0.4;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.8;
  }
}

.message.error {
  background-color: #fff2f0;
  color: #cf1322;
  border: 1px solid #ffccc7;
} 